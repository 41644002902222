.perfect-datetimepicker {
	position: relative;
	width: 100%;
	margin: 0 auto;
	padding: 0;
	font-size: 14px;
	color: #374254;
	background-color: #FAFAFA;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	line-height: 0;
	overflow: hidden;
	border: 1px solid #e8ebf3;
  
	* {
	  -webkit-box-sizing: border-box;
	  -moz-box-sizing: border-box;
	  box-sizing: border-box;
	}
  
	table {
	  width: 100%;
	  margin: 0;
	  border: 0;
	  border-collapse: separate;
	  border-spacing: 2px;
	  background-color: white;
	  padding: 3px;
  
	  td {
		border: 0;
		text-align: center;
		padding: 2px;
		width: 34px;
		height: 27px;
		cursor: pointer;
		vertical-align: middle;
		-webkit-border-radius: 3px;
		-moz-border-radius: 3px;
		border-radius: 3px;
  
		&.weekend {
		  color: #ff382b;
		}
	  }
  
	  &.mt tbody td {
		width: 58px;
		height: 38px;
	  }
	}
  
	td {
	  &.day {
		width: 14.28571429%;
	  }
  
	  &.name {
		font-weight: bold;
	  }
  
	  &.oday {
		color: #778aaa;
		cursor: auto;
	  }
  
	  &.disabled {
		color: #778aa9;
		cursor: auto;
	  }
  
	  i {
		font-size: 18px;
		font-weight: bold;
	  }
  
	  table.tt {
		border: 0;
		padding: 0;
	  }
	}
  
	table.tt {
	  padding: 5px;
  
	  td {
		&.hover {
		  border: 0;
		}
  
		&.common {
		  width: 5px;
		}
	  }
  
	  tbody td {
		width: 40px;
		height: 14px;
	  }
  
	  input {
		outline: none;
		border: 1px solid #e8ebf3;
		margin: 0 auto;
		padding: 0 !important;
		width: 38px;
		height: 24px;
		text-align: center;
		font-size: 12px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		vertical-align: middle;
		-webkit-border-radius: 3px;
		-moz-border-radius: 3px;
		border-radius: 3px;
		-moz-transition: border-color ease-in-out 0.2s, box-shadow ease-in-out 0.2s;
		-ms-transition: border-color ease-in-out 0.2s, box-shadow ease-in-out 0.2s;
		-webkit-transition: border-color ease-in-out 0.2s, box-shadow ease-in-out 0.2s;
		-o-transition: border-color ease-in-out 0.2s, box-shadow ease-in-out 0.2s;
		transition: border-color ease-in-out 0.2s, box-shadow ease-in-out 0.2s;
  
		&[type=number] {
		  &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
			-webkit-appearance: none;
			margin: 0;
		  }
  
		  -webkit-appearance: textfield;
		  -moz-appearance: textfield;
		  appearance: textfield;
		}
	  }
	}
  
	.buttonpane {
	  border-top: 1px solid #e9ecfb;
	}
  
	tbody td {
	  &.hover {
		background-color: #e9ecfb;
		padding: 1px;
		border: 1px solid #e9ecfb;
	  }
  
	  &.today {
		font-weight: bold;
		color: $primary;
	  }
  
	  &.selected {
		font-weight: bold;
		padding: 1px;
		color: white;
	  }
	}
  
	tfoot td.hover {
	  background-color: #e9ecfb;
	  padding: 1px;
	  border: 1px solid #e9ecfb;
	}
  
	thead td {
	  &.hover {
		background-color: #e9ecfb;
		padding: 1px;
		border: 1px solid #e9ecfb;
	  }
  
	  &.title {
		font-weight: bold;
		text-align: center;
		padding: 2px;
	  }
	}
  }
  
  i {
	font-family: "finex";
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
  
	&.icon-datepicker-minus:before {
	  content: '\e601';
	}
  
	&.icon-datepicker-plus:before {
	  content: '\e603';
	}
  
	&.icon-datepicker-prev:before {
	  content: '\e602';
	}
  
	&.icon-datepicker-next:before {
	  content: '\e600';
	}
  }
  
  @font-face {
	font-family: 'finex';
	src: url('../plugins/calendar/resources/fonts/iconfont.eot');
  
	/* IE9*/
	src: url('../plugins/calendar/resources/fonts/iconfont.eot?#iefix') format("embedded-opentype"), url('../plugins/calendar/resources/fonts/iconfont.woff') format("woff"), url('../plugins/calendar/resources/fonts/iconfont.ttf') format("truetype"), url('../plugins/calendar/resources/fonts/iconfont.svg#svgFontName') format("svg");
  
	/*  iOS 4.1- */
  }