/**
 * jQuery Flexdatalist basic stylesheet.
 *
 * Version:
 * 2.2.1
 *
 * Github:
 * https://github.com/sergiodlopes/jquery-flexdatalist/
 *
 */

.flexdatalist-results {
    position: absolute;
    top: 0;
    left: 0;
    border-top: none;
    background: #fff;
    border-radius: 7px !important;
    z-index: 99;
    max-height: 300px;
    overflow-y: auto;
    box-shadow: 0 4px 5px rgb(108 106 169 / 38%);
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 5px;

    li {
        border-bottom: 0px solid #efecfb;
        padding: 8px 15px;
        font-size: 14px;
        line-height: 20px;
        cursor: pointer;

        &.active {
            background-color: #ececf3;
        }

        span.highlight {
            font-weight: 700;
            text-decoration: none;
            background: none;
            border: 0;
        }

        &.no-results {
            font-style: italic;
        }

        &.group {
            background: #F3F3F4;
            color: #666;
            padding: 8px 8px;
        }

        .group-name {
            font-weight: 700;
        }

        .group-item-count {
            font-size: 85%;
            color: #777;
            display: inline-block;
            padding-left: 10px;
        }
    }
}

/**
   * Grouped items
   */

/**
   * Multiple items
   */

.flexdatalist-multiple {
    &:before {
        content: '';
        display: block;
        clear: both;
    }

    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: left;
    cursor: text;

    &.disabled {
        background-color: #eee;
        cursor: default;
    }

    &:after {
        content: '';
        display: block;
        clear: both;
    }

    li {
        display: inline-block;
        position: relative;
        margin: 5px;
        float: left;

        &.input-container {
            border: none;
            height: auto;
            padding: 0 0 0 4px;
            line-height: 24px;

            input {
                border: none;
                height: auto;
                padding: 0 0 0 4px;
                line-height: 24px;
            }
        }

        &.value {
            display: inline-block;
            padding: 2px 25px 2px 7px;
            background: #eee;
            border-radius: 3px;
            line-height: 20px;
        }

        &.toggle {
            cursor: pointer;
            transition: opacity ease-in-out 300ms;

            &.disabled {
                text-decoration: line-through;
                opacity: 0.80;
            }
        }

        &.value span.fdl-remove {
            font-weight: 700;
            padding: 2px 5px;
            font-size: 20px;
            line-height: 20px;
            cursor: pointer;
            position: absolute;
            top: 0;
            right: 0;
            opacity: 0.70;

            &:hover {
                opacity: 1;
            }
        }
    }
}